@font-face {
    font-family: 'ProximaNovaBlack';
    src: url('../assets/ProximaNova-Black.ttf') format('truetype');
    font-style: black;
    font-weight: 800;
  }
  @font-face {
    font-family: 'ProximaNovaBold';
    src: url('../assets/ProximaNova-Bold.ttf') format('truetype');
    font-style: black;
    font-weight: 700;
  }
  @font-face {
    font-family: 'ProximaNovaExtraBold';
    src: url('../assets/ProximaNova-Extrabld.ttf') format('truetype');
    font-style: black;
    font-weight: 600;
  }
  @font-face {
    font-family: 'ProximaNovaLight';
    src: url('../assets/ProximaNova-Light.ttf') format('truetype');
    font-style: black;
    font-weight: 300;
  }
  @font-face {
    font-family: 'ProximaNovaRegular';
    src: url('../assets/ProximaNova-Regular.ttf') format('truetype');
    font-style: black;
    font-weight: 400;
  }
  @font-face {
    font-family: 'ProximaNovaSemibold';
    src: url('../assets/ProximaNova-Semibold.ttf') format('truetype');
    font-style: black;
    font-weight: 500;
  }
  @font-face {
    font-family: 'ProximaNovaThin';
    src: url('../assets/ProximaNovaT-Thin.ttf') format('truetype');
    font-style: black;
    font-weight: 200;
  }

*{
    margin: 0;
    padding: 0;

}
body{
    background-size: cover;
}
$verdeOscuro:#347C52;
$verdeClaro:#6DB32D;
$naranja:#F3B114;
$gris: #5b6f70;
// generales
.modalTestContainer{
    background-color: #fff;
    width: 300px;
    height: 300px;
    border-radius: 20px;
    display: grid;
    align-self: center;
    padding: 15px;
    .close_button_form{
        display: flex;
        flex-direction: row;
        justify-self: self-end;
        height: fit-content;
        button{
            width: 50px;
            height: 50px;
            border: none;
            background-color: $naranja;
            color: #fff;
            border-radius: 20px;
            font-weight: 500;
        }
    }
    .content-buttons-modal{
        display: flex;
        flex-direction: column;
        gap: 16px;
        button{
            border: none;
            background-color: $naranja;
            color: #fff;
            border-radius: 20px;
            font-weight: 500;
            padding: 8px 16px;
            width: 100%;
        }

    }
}
.container_form{
    .form-idiomas, .form-modalidades{
        display: none;
    }

}
ul{
    a{
        text-decoration: none;
        color: $verdeClaro;
        font-weight: 600;
    }
    a:hover{
        border-bottom: solid 2px $naranja;
        text-decoration: none;
        color: $verdeClaro;
        font-weight: 600;
    }
}
.modalIdiomas{
    .form-home, .form-modalidades{
        display: none;
    }
}
.modalModalidades{
    .form-home, .form-idiomas{
        display: none;
    }
}

.pruebasModal{
    .contenedor-buttons-modal-testNivel{
        display: none;
    }
    .contenedor-buttons-modal-plataforma{
        a{
            display: flex;
    width: 100%;
    text-decoration: none;
    button{
        display: flex;
        background: #fff;
        color: #f3b114;
        align-items: center;
        justify-content: space-between;
        text-align: start;
        border: solid 2px $naranja;
        img{
            width: 50px;
        }
    }
    button:hover{
        background: $verdeClaro;
        color: #fff;
    }
        }

    }
}
.testModal{
        .contenedor-buttons-modal-plataforma{
            display: none!important;
        }
        .contenedor-buttons-modal-testNivel{
            display: flex!important;
        }

}
.cuandoInicio{
    text-align: start;
}
.buttons-pruebas{
    text-align: start;
    flex-direction: column;
}
h1{
    font-family: 'ProximaNovaBlack';
}
h2{
    font-family: 'ProximaNovaExtrabold';
}

h5, h3{
    font-family: 'ProximaNovaBold';
}
h6, button{
    font-family: 'ProximaNovaSemibold';
    strong{
        font-family: 'ProximaNovaExtrabold';
    }
}
p, span, input, label, a{
    font-family: 'ProximaNovaRegular';
}

input::placeholder{
    padding-left: 5px;

}
button:hover{
    background-color: #fff;
    color: $naranja;
    border: solid 2px $naranja;
}
.body_blog{
    .blog-title{
        color: $verdeOscuro;
        text-align: center;
        h3{
        font-size:45px ;            
        }

    }
    .main-blog{
        display: flex;
        justify-content: space-around;
        align-items: center;
        .blog-container{
            display: flex;
            flex-direction: column;
            gap: 50px;
            .contenedor-imagen{
                display: flex;
                place-content: center;
                height: 300px;
                align-items: center;
                img{
                    width: 300px;
                    height: fit-content;
                }
            }
            .contenedor-texto{
                width: auto;
                height: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }

            h5{
                font-size: 30px;
            }
            a{
                text-decoration: none;
                color: #000;
                text-align: center;

            }
            a:hover{
                color: $verdeClaro;
            }
            p{
                text-align: center;
            }
        }
    }
}
.blog-sub-category{
    color: $gris;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 250px 50px 250px;
    .category-title{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 60px 0 100px 0;
        gap: 24px;
        h3{
            font-weight: 300;
            font-size: 45px;
        }
        p{
            font-size: 20px;
        }
    }
    .category-section{
        display: flex;
        flex-direction: column;
        gap: 24px;

        h4{
            font-weight: 700;
            font-size: 40px;
        }
        p{
            font-size: 20px;
        }
        .nota{
            font-size: 18px;
            font-weight: 100;
        }
    }
    .contenedor-imagen{
        display: flex;
        justify-content: center;
        margin: 100px 0;
    }
}
.navbar{
    padding: 24px 110px;
    ul{
        gap: 10px;
    }
    .nav{
        justify-content: end;
    }
    .nav-item{
        a{
            color: $verdeClaro;
            font-weight: 600;
            font-size: 15px;
            text-decoration: none;
        }
    }
    .navbar-brand{
        width: 296px;
        img{
            width: 296px;
        }
    }
}


.home{


    .header_index{
        padding: 100px 161px 0 161px;
        display: flex;
        justify-content: center;
        background-image: url('../multimedia/bg-header-index.png');
        .display_flex-container{
            display: flex;
            gap: 150px;
        }
        .container_title{
            width: 600px;
            h2{
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 37px;
                color: $naranja;
            }
            h1{
                color: $verdeOscuro;
                font-style: normal;
                font-weight: 800;
                font-size: 45px;
                line-height: 109.3%;
            }
            p{
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: $verdeClaro;
                margin: 0;
            }
            .strong{
                font-weight: 700;
            }
            .light-dark{
                color: $verdeOscuro;
                font-size: 16px;
            }
            .span{
                width: 463px;
                height: 55px;
                border: solid 1px $verdeClaro;
                padding:14px 50px ;
                align-items: center;
                display: flex;
                border-radius: 10px;
                margin: 12px 0 0 0 ;
                p{
                    line-height: 14px;
                    font-size: 14px;
                    color: $verdeOscuro;
                    font-weight: 400;
                }
            }
        }
    
    }
    .header_cambridge{
        background-image: url('../multimedia/bg-header-cambridge.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 150px 161px 161px;
    }
.cambridge-alumnos{
    margin-top: -175px;
    .glider-alumnos{
        background: linear-gradient(217.61deg, #6FB32D -2.73%, #72B52F 21.27%, #61B06C 100%);
    }
}
}
.body_idiomas{
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -160px;
}


.header_idiomas{
    text-align: center;
    padding-top: 240px;
    padding-bottom: 220px;
    text-align: -webkit-center;
    background-image: url('../multimedia/bg-header-idiomas.png');
    h1{
        color: $verdeOscuro;
        font-weight: 800;
        font-size: 45px;
    }
    h2{
        color: $verdeClaro;
        font-weight: 400;
        width: 400px;
        font-size: 20px;
        strong{
            color: $naranja;
        }
    }
}
.title-section{
    text-align: center;
    color: #fff;
    padding: 119px 0 50px 0;
    font-size: 24px;
    font-weight: 800;
    background:none;
}

.btn-principal{
    border: none;
    background-color: $naranja;
    color: #fff;
    border-radius: 20px;
    font-weight: 500;
}

form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 0px 20px 20px rgb(96 91 91 / 25%);
    padding: 36px;
    border-radius: 20px;
    width: 436px;
    height: 502px;
    gap: 20px;
    background-color: #fff;
    z-index: 4;
    position: relative;
    h5{
        color: $verdeOscuro;
        font-weight: 700;
    }
    .form-nombre{
        display: flex;
        gap: 16px;
        font-size: 18px;
        padding: 24px 0;
        input{
            width: 174px;
        }
    }
    .form-datos{
        padding: 0 0 24px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        font-size: 18px;
    }
    input{
        border-bottom: solid 1px #636363;
        border-top: none;
        border-right: none;
        border-left: none;
        color: #000;
    }
    input::placeholder{
        color: #000;
    }
    .form-buttons{
        padding: 0 0 24px 0;
        display: flex;
        gap: 4px;
        width: 100%;
        font-size: 17px;
        justify-content: space-between;
        input{
            margin-right: 5px;
        }
        div{
            display: flex;
        }

    }
    .btn-enviar{
        width: 100%;
        
    }
    .form-datos{
        display: flex;
        flex-direction: column ;
    }
    .btn-principal{
        padding: 8px 36px;
        width: 80%;
    }
}

.body_index, .body_idiomas, .body-cambridge{
    .main_index{
        padding: 175px 0 0 0;
        margin-top: -153px;
        background: linear-gradient(205.25deg, #5EAD66 0%, #37855B 50.85%, #206E57 100%);
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 0.51%, 1.00% 0.45%, 2.00% 0.41%, 3.00% 0.40%, 4.00% 0.41%, 5.00% 0.45%, 6.00% 0.51%, 7.00% 0.59%, 8.00% 0.70%, 9.00% 0.83%, 10.00% 0.97%, 11.00% 1.14%, 12.00% 1.33%, 13.00% 1.53%, 14.00% 1.75%, 15.00% 1.98%, 16.00% 2.22%, 17.00% 2.47%, 18.00% 2.74%, 19.00% 3.00%, 20.00% 3.27%, 21.00% 3.55%, 22.00% 3.82%, 23.00% 4.09%, 24.00% 4.35%, 25.00% 4.61%, 26.00% 4.86%, 27.00% 5.10%, 28.00% 5.33%, 29.00% 5.54%, 30.00% 5.74%, 31.00% 5.92%, 32.00% 6.08%, 33.00% 6.22%, 34.00% 6.34%, 35.00% 6.44%, 36.00% 6.51%, 37.00% 6.57%, 38.00% 6.59%, 39.00% 6.60%, 40.00% 6.58%, 41.00% 6.54%, 42.00% 6.47%, 43.00% 6.38%, 44.00% 6.27%, 45.00% 6.13%, 46.00% 5.98%, 47.00% 5.81%, 48.00% 5.62%, 49.00% 5.41%, 50.00% 5.19%, 51.00% 4.95%, 52.00% 4.71%, 53.00% 4.45%, 54.00% 4.19%, 55.00% 3.92%, 56.00% 3.65%, 57.00% 3.38%, 58.00% 3.11%, 59.00% 2.84%, 60.00% 2.57%, 61.00% 2.32%, 62.00% 2.07%, 63.00% 1.83%, 64.00% 1.61%, 65.00% 1.40%, 66.00% 1.21%, 67.00% 1.03%, 68.00% 0.88%, 69.00% 0.74%, 70.00% 0.63%, 71.00% 0.54%, 72.00% 0.47%, 73.00% 0.42%, 74.00% 0.40%, 75.00% 0.40%, 76.00% 0.43%, 77.00% 0.48%, 78.00% 0.55%, 79.00% 0.65%, 80.00% 0.77%, 81.00% 0.91%, 82.00% 1.07%, 83.00% 1.24%, 84.00% 1.44%, 85.00% 1.65%, 86.00% 1.88%, 87.00% 2.12%, 88.00% 2.36%, 89.00% 2.62%, 90.00% 2.89%, 91.00% 3.16%, 92.00% 3.43%, 93.00% 3.70%, 94.00% 3.97%, 95.00% 4.24%, 96.00% 4.50%, 97.00% 4.75%, 98.00% 5.00%, 99.00% 5.23%, 100.00% 5.45%);
        .cards-modalidades-contenedor{

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
            row-gap: 30px;
            padding: 30px 0;
            .contenedor-card{
                border: solid 5px #fff;
                width: 252px;
                height: 247px;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                gap: 0px;
                padding: 46px 23px 0 23px;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url('../multimedia/card-fondo.png');
                border-radius: 10%;
                box-shadow: #147640 6px 6px 6px 5px;
                a{
                    width: 100%;
                }
                h6{
                    color: $verdeOscuro;
                    font-size: 20px;
                    width: 170px;
                    line-height: 20px;
                }
                p{
                    color: $verdeClaro;
                    font-size: 14px ;
                    font-weight: 400;
                    margin: 0;
                    line-height: 15px;
                }
                span{
                    color: $naranja;
                    font-size: 14px;
                }
                .contenedor-btn{
                    padding: 0;
                    display: flex;
                    justify-content: start;
                    width: 80%;
                    justify-content: flex-end;
                    background-image: none;
                    button{
                        padding: 5px 5px;
                        width: 100%;
                    }
                }
    
            }
            .contenedor-card:hover{

                background-size: cover;
                background-repeat: no-repeat;
                transform: translate(0, -10px);
            }
        }
        .extra-contenedor{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.58) 0%, rgba(58, 136, 92, 0.52) 100%);

            clip-path: polygon(100% 0%, 0% 0% , 0.00% 88.56%, 2.00% 86.91%, 4.00% 85.32%, 6.00% 83.85%, 8.00% 82.52%, 10.00% 81.38%, 12.00% 80.45%, 14.00% 79.77%, 16.00% 79.34%, 18.00% 79.18%, 20.00% 79.29%, 22.00% 79.68%, 24.00% 80.32%, 26.00% 81.21%, 28.00% 82.32%, 30.00% 83.62%, 32.00% 85.08%, 34.00% 86.65%, 36.00% 88.29%, 38.00% 89.97%, 40.00% 91.63%, 42.00% 93.24%, 44.00% 94.74%, 46.00% 96.11%, 48.00% 97.30%, 50.00% 98.27%, 52.00% 99.02%, 54.00% 99.51%, 56.00% 99.73%, 58.00% 99.68%, 60.00% 99.35%, 62.00% 98.77%, 64.00% 97.93%, 66.00% 96.87%, 68.00% 95.61%, 70.00% 94.19%, 72.00% 92.64%, 74.00% 91.01%, 76.00% 89.34%, 78.00% 87.67%, 80.00% 86.04%, 82.00% 84.51%, 84.00% 83.11%, 86.00% 81.88%, 88.00% 80.85%, 90.00% 80.05%, 92.00% 79.50%, 94.00% 79.22%, 96.00% 79.21%, 98.00% 79.47%, 100.00% 79.99%);
            padding: 150px  0 200px 0;
            .contenedor-card{
                h6{
                    color: $verdeOscuro;
                    font-size: 20px;
                    width: 190px;
                    line-height: 20px;
                }
    
            }
        }
        .idiomas{
            padding-top: 200px;
        }
    }
    .idiomas-index{
        padding: 0;
        margin-top: -186px;
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 4.73%, 2.00% 4.84%, 4.00% 5.01%, 6.00% 5.25%, 8.00% 5.54%, 10.00% 5.88%, 12.00% 6.25%, 14.00% 6.64%, 16.00% 7.03%, 18.00% 7.42%, 20.00% 7.79%, 22.00% 8.12%, 24.00% 8.40%, 26.00% 8.63%, 28.00% 8.79%, 30.00% 8.88%, 32.00% 8.90%, 34.00% 8.84%, 36.00% 8.71%, 38.00% 8.52%, 40.00% 8.26%, 42.00% 7.95%, 44.00% 7.60%, 46.00% 7.22%, 48.00% 6.83%, 50.00% 6.44%, 52.00% 6.05%, 54.00% 5.70%, 56.00% 5.38%, 58.00% 5.12%, 60.00% 4.91%, 62.00% 4.77%, 64.00% 4.71%, 66.00% 4.71%, 68.00% 4.79%, 70.00% 4.95%, 72.00% 5.16%, 74.00% 5.44%, 76.00% 5.76%, 78.00% 6.12%, 80.00% 6.51%, 82.00% 6.90%, 84.00% 7.29%, 86.00% 7.67%, 88.00% 8.01%, 90.00% 8.31%, 92.00% 8.56%, 94.00% 8.74%, 96.00% 8.86%, 98.00% 8.90%, 100.00% 8.87%);
    }
}
.body-cambridge{
    .display_flex-container{
        gap:150px;
        .container_title{
            margin-top: 50px;
        }
    }
    h5{
        font-weight: 800;
        font-size: 25px;
        line-height: 30px;
        color: #fff;
        text-align: center;
    }
    .container_form{
        padding: 0;
    }
    form{
        h5{
            color: #347C52;
            font-weight: 700;
        }

    }
    .main_index{
        margin-top: -104px;

        .cards-modalidades-contenedor{

            padding: 70px 0;
        }
        .glider-alumnos{
            margin-top: 175px;
        }
    }
}



.footer{
    background-color: #3A3A3A;
    padding: 100px 0;
    .footer-header{
        display: flex;
        color: #fff;
        justify-content: center;
        padding: 0px 250px;
        gap: 100px;
        align-items: center;
        h6{
            font-weight: 400;
            font-size: 25px;
            width: 250px;
        }
        span{
            background-color: #fff;
            width: 1px;
            height: 100px;
        }
        ul{
            li{
                list-style-type: none;
                a{
                    color: #fff;
                }
            }
        }

    }
    .footer-mid{
        align-items: center;
        display: flex;
        color: #fff;
        justify-content: center;
        padding: 10px 310px;
        gap: 30px;
        p{
            margin: 0;
        }
        .logo{
            width: 218px;
            text-align: end;
        }
        span{
            background-color: #fff;
            width: 1px;
            height: 20px;
        }
    }
    .mid-line{
        background-color: #fff;
        height: 1px;
        margin: 0 23%;
    }
    .footer-redes{
        display: flex;
        color: #fff;
        justify-content: center;
        gap: 16px;
        padding: 16px 0;
        align-items: center;
        span{
            background-color: #fff;
            width: 1px;
            height: 20px;
        }
        img{
            width: 30px;
            height: 30px;
            margin: 0;
        }
    }
}




@media screen and (max-width: 768px){

    .contenedor-wsp-button{
        position: absolute;
        left: 85%;
        top: 65%;
        z-index: 10;

        a{

            position: fixed;
        }
        .button-wsp{

            img{
                width: 40px;
                height: auto;

            }
        }
    }
    .navbar{
        padding: 16px 12px;
        justify-content: center;
        display: flex;
        ul{
            gap: 10px;
            justify-content: center;
        }
        .nav-item{
            a{
                color: $verdeClaro;
                font-weight: 600;
                font-size: 15px;
                text-decoration: none;
            }
        }
        .logo-web{
            display: none;
        }
        .navbar-brand {
            width: fit-content;
            img {
            width: 30px;
        }}
    }
    .body_blog{
        .main-blog{
            flex-direction: column;
        }
    }
    .blog-sub-category{
        padding: 0 16px 0 16px;
        .category-title{
            h3{
                font-size: 28px;
            }
        }
        .category-section{
            h4{
                font-size: 24px;
            }
            p{
                font-size: 16px;
            }
        }

        .contenedor-imagen{
        img{
            width: 300px;
        }
        }
    }

    .body_index, .body_idiomas{
        background-size: cover;
        form{
            width: 100%;
            margin: 10px 0 0 0;
            padding: 16px 16px;
            height: max-content;
            .form-nombre{
                flex-direction: column;
                input{
                    width: 100%;
                }
            }
            .form-buttons{
                font-size: 16px;
                gap: 5px;
                flex-direction: column;
            }
        }
        .header_idiomas{
            margin-top: 125px;
            text-align: center;
            padding-top: 100px;
            text-align: -webkit-center;
            h2{
                font-size: 18px;
                width: 300px;

            }
            h1{
                font-size: 25px;
                width: 300px;
            }
            p{
                font-size: 16px;
            }

        }
        .header_index{
            display: flex;
            flex-direction: column;
            padding: 50px 16px;
            background-image: none;
            .display_flex-container{
            flex-direction: column;   
            gap: 50px;  
            .container_title{
                width: 300px;
                h2{
                    font-size: 18px;

                }
                h1{
                    font-size: 25px;
                    width: 300px;
                }
                p{
                    font-size: 16px;
                }
            }
            .container_form{
                margin-top: 50px;

            }           
            }

        }
        .main_index{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 50px 0;
            .openModal{
                width: 100%;
                form{
                    width: 300px;
                }
            }
            .idiomas{
                padding: 0;
            }
            .cards-modalidades-contenedor{
                width: 100%;
                align-items: center;
                display: flex;
                flex-direction: column; 
                .contenedor-card{
                    align-items: center;
                    padding: 0;
                    gap: 8px;
                    width: 300px;
                    height: 90px;
                    background: none;
                    border:none;
                    flex-direction: row;
                    justify-content: space-between;
                    box-shadow: none;
                    h6{
                        color: #fff;
                        margin-top: 10px;   
                        width: 250px;
                        height: 90px;
                        background-image: url('../multimedia/bg-mobile-card.png');
                        border: solid 2px #fff;
                        background-size: cover;
                        padding: 18px 18px;
                        box-shadow: #edf7f18f 3px 6px 20px 0px;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    p, span, .contenedor-btn{
                        display: none;
                    }

                    .button-mobile{
                        button{
                            width: 90px;
                            height: 90px;
                            border: none;
                            background-color: $naranja;
                            color: #fff;
                            font-weight: 500;
                            box-shadow: #edf7f18f 3px 6px 20px 0px;
                            border-radius: 20px;
                        }
                        
                    }
                }
                .contenedor-card:hover{
                    background:none;
                    border: none;
                    h6{
                        color: $verdeOscuro;
                        background-image: url('../multimedia/bg-hover-card-mobile.png');
                    }
                }
            }

        }
    }

    .body_idiomas{
        background-size: cover;
        .header_idiomas{
            padding-bottom: 161px;
            background-size: cover;
            h1{
                margin-top: -50px;
            }
        }
        .main_index{
            padding: 0;
            margin-top: -109px;
        }

    }
    .footer{
        .displayNone{
            display: none;
        }
        .footer-header{
            flex-direction: column;
            padding: 0;
            text-align: center;
            gap: 10px;
            h6{
                font-size: 16px;
            }
            ul{
                padding: 0;
                font-size: 14px;
            }
        }
        .footer-mid{
            padding: 10px 10px;
            font-size: 14px;
            text-align: center;
            gap: 10px;
            .logo{
                width: 200px;
            }
        }
    }
    .alumnos{
display: none;
    }
    .gliderMobile{
        margin-top: -70px;
        background: linear-gradient(205.25deg, #5EAD66 0%, #37855B 50.85%, #206E57 100%);
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 0.51%, 1.00% 0.45%, 2.00% 0.41%, 3.00% 0.40%, 4.00% 0.41%, 5.00% 0.45%, 6.00% 0.51%, 7.00% 0.59%, 8.00% 0.70%, 9.00% 0.83%, 10.00% 0.97%, 11.00% 1.14%, 12.00% 1.33%, 13.00% 1.53%, 14.00% 1.75%, 15.00% 1.98%, 16.00% 2.22%, 17.00% 2.47%, 18.00% 2.74%, 19.00% 3.00%, 20.00% 3.27%, 21.00% 3.55%, 22.00% 3.82%, 23.00% 4.09%, 24.00% 4.35%, 25.00% 4.61%, 26.00% 4.86%, 27.00% 5.10%, 28.00% 5.33%, 29.00% 5.54%, 30.00% 5.74%, 31.00% 5.92%, 32.00% 6.08%, 33.00% 6.22%, 34.00% 6.34%, 35.00% 6.44%, 36.00% 6.51%, 37.00% 6.57%, 38.00% 6.59%, 39.00% 6.60%, 40.00% 6.58%, 41.00% 6.54%, 42.00% 6.47%, 43.00% 6.38%, 44.00% 6.27%, 45.00% 6.13%, 46.00% 5.98%, 47.00% 5.81%, 48.00% 5.62%, 49.00% 5.41%, 50.00% 5.19%, 51.00% 4.95%, 52.00% 4.71%, 53.00% 4.45%, 54.00% 4.19%, 55.00% 3.92%, 56.00% 3.65%, 57.00% 3.38%, 58.00% 3.11%, 59.00% 2.84%, 60.00% 2.57%, 61.00% 2.32%, 62.00% 2.07%, 63.00% 1.83%, 64.00% 1.61%, 65.00% 1.40%, 66.00% 1.21%, 67.00% 1.03%, 68.00% 0.88%, 69.00% 0.74%, 70.00% 0.63%, 71.00% 0.54%, 72.00% 0.47%, 73.00% 0.42%, 74.00% 0.40%, 75.00% 0.40%, 76.00% 0.43%, 77.00% 0.48%, 78.00% 0.55%, 79.00% 0.65%, 80.00% 0.77%, 81.00% 0.91%, 82.00% 1.07%, 83.00% 1.24%, 84.00% 1.44%, 85.00% 1.65%, 86.00% 1.88%, 87.00% 2.12%, 88.00% 2.36%, 89.00% 2.62%, 90.00% 2.89%, 91.00% 3.16%, 92.00% 3.43%, 93.00% 3.70%, 94.00% 3.97%, 95.00% 4.24%, 96.00% 4.50%, 97.00% 4.75%, 98.00% 5.00%, 99.00% 5.23%, 100.00% 5.45%);
        .glider-contain{
            .glider-slide{
                height: min-content;
                width: 524px;
                display: flex;
                flex-direction: column;
                align-self: center;
                border: solid 2px $naranja;
                border-radius: 0px 20px 0 20px;
                padding: 5px;
                margin: 50px 10px 10px 10px;
                box-shadow: $verdeOscuro 3px 6px 17px 0px;
                img{
                    border-radius: 0px 20px 0 20px;
                }
            }
        }
    }
    .body-cambridge{
        background-size: cover;
        margin-top: 50px;
        .header_index{
            background-image: none;
            background-size: contain;
            padding: 100px 161px 0 161px;
            .display_flex-container{
                flex-direction: column;   
                gap: 50px;  
                margin-top: -95px;
                .container_title{
                    width: 300px;
                    h2{
                        font-size: 18px;
                        line-height: 24px;
                    }
                    h1{
                        font-size: 25px;
                        width: 300px;
                    }
                    p{
                        font-size: 16px;
                    }
                    .span{
                        width: 300px;
                        padding: 8px;
                        p{
                            font-size: 12px;
                        }
                    }
                }
                .container_form{
                    margin-top: 80px;
                    form{
                        margin-top: 0;
                    }
    
                }           
                }
        
        }
        .main_index{
            .cards-modalidades-contenedor{
                width: 100%;
                align-items: center;
                display: flex;
                flex-direction: column; 
                .contenedor-card{
                    align-items: center;
                    padding: 0;
                    gap: 8px;
                    width: 300px;
                    height: 90px;
                    background: none;
                    border:none;
                    flex-direction: row;
                    justify-content: space-between;
                    box-shadow: none;
                    h6{
                        color: #fff;
                        margin-top: 10px;   
                        width: 250px;
                        height: 90px;
                        background-image: url('../multimedia/bg-mobile-card.png');
                        border: solid 2px #fff;
                        background-size: cover;
                        padding: 18px 18px;
                        box-shadow: #edf7f18f 3px 6px 20px 0px;
                        border-radius: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    p, span, .contenedor-btn{
                        display: none;
                    }
    
                    .button-mobile{
                        button{
                            width: 90px;
                            height: 90px;
                            border: none;
                            background-color: $naranja;
                            color: #fff;
                            font-weight: 500;
                            box-shadow: #edf7f18f 3px 6px 20px 0px;
                            border-radius: 20px;
                        }
                        
                    }
                }
                .contenedor-card:hover{
                    background:none;
                    border: none;
                    h6{
                        color: $verdeOscuro;
                        background-image: url('../multimedia/bg-hover-card-mobile.png');
                    }
                }
            }
        }
        form{
            width: 100%;
            margin: 150px 0 0 0;
            padding: 16px 16px;
            height: max-content;
            .form-nombre{
                flex-direction: column;
                input{
                    width: 100%;
                }
            }
            .form-buttons{
                font-size: 16px;
                gap: 5px;
                flex-direction: column;
            }
        }
        .gliderMobile{
            background-color: $verdeOscuro;
            margin-top: 5px;
            .glider-contain{
                .glider-slide{
                    height: min-content;
                    width: 524px;
                    display: flex;
                    flex-direction: column;
                    align-self: center;
                    border: solid 2px $naranja;
                    border-radius: 0px 20px 0 20px;
                    padding: 5px;
                    margin: 50px 10px 10px 10px;
                    img{
                        border-radius: 0px 20px 0 20px;
                    }
                }
            }
        }
    }

}
@media (min-width:769px) and (max-width:1279px){
    .body_blog{
        .main-blog{
            .blog-container{
                .contenedor-imagen{
                    img{
                        width: 200px;
                    }
                }
                .contenedor-texto{
                 h5{
                    font-size: 16px;
                    width: 200px;
                 }   
                 p{
                    font-size: 14px;
                 }
                }
            }
        }
    }
.blog-sub-category{
    padding: 0 100px;
}
.body_index{
    .main_index{
        margin-top: -294px;
    }
}
.main_index{
    margin-top: -294px;
}
    .navbar{
        padding: 8px 16px;
        .navbar-brand{
            .logo-web{
                width: 170px;
            }
            .logo-mobile{
                display: none;
            }
        }
        ul{
            .nav-item{
                font-size: 10px;
            }
        }

    }
    .header_index{
        padding: 100px 50px !important;
    }
    .header_index, .header_cambridge{
        gap: 50px;

        .display_flex-container{
            justify-content: space-around;
            gap: 65px;
            .container_title{
                width: fit-content;
                h2{
                    font-size: 22px;
                }
                h1{
                    font-size: 35px;
                    width: 300px;
                }
                .span{
                    width: 400px;
                }
            }
            .container_form{
                form{
                    width: 300px;
                    height: max-content;
                    padding: 16px;
                    .form-nombre{
                        flex-direction: column;
                        input{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 769px){
    .logo-mobile{
        display: none;
    }
    .footer{
        .footer-header{
            gap: 10px;
            padding: 0;
        }
        .footer-mid{
            gap: 10px;
            padding: 50px 0;
        }
    }


    .contenedor-wsp-button{
        position: absolute;
        left: 90%;
        top: 65%;
        z-index: 10;
        a{
            position: fixed;
        }
        .button-wsp{
            img{
                width: 60px;
                height: auto;
            }
        }
    }
    .gliderMobile{
        display: none;
    }
    .body_index{

        background-size: cover;
        background-repeat: no-repeat;
        margin-top: -50px;
    }
    .glider-alumnos{
        width: 100%;
        padding: 0 200px;
        margin-top: 40px;
        padding-bottom: 70px;
        padding-top: 210px;
        background-color: $verdeClaro;
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 9.24%, 1.00% 8.61%, 2.00% 8.00%, 3.00% 7.42%, 4.00% 6.86%, 5.00% 6.35%, 6.00% 5.88%, 7.00% 5.47%, 8.00% 5.12%, 9.00% 4.84%, 10.00% 4.62%, 11.00% 4.48%, 12.00% 4.41%, 13.00% 4.41%, 14.00% 4.49%, 15.00% 4.65%, 16.00% 4.87%, 17.00% 5.17%, 18.00% 5.53%, 19.00% 5.95%, 20.00% 6.42%, 21.00% 6.94%, 22.00% 7.50%, 23.00% 8.09%, 24.00% 8.70%, 25.00% 9.33%, 26.00% 9.96%, 27.00% 10.59%, 28.00% 11.21%, 29.00% 11.80%, 30.00% 12.37%, 31.00% 12.89%, 32.00% 13.37%, 33.00% 13.80%, 34.00% 14.17%, 35.00% 14.48%, 36.00% 14.72%, 37.00% 14.89%, 38.00% 14.98%, 39.00% 15.00%, 40.00% 14.94%, 41.00% 14.81%, 42.00% 14.60%, 43.00% 14.33%, 44.00% 13.99%, 45.00% 13.59%, 46.00% 13.13%, 47.00% 12.63%, 48.00% 12.08%, 49.00% 11.50%, 50.00% 10.89%, 51.00% 10.27%, 52.00% 9.64%, 53.00% 9.01%, 54.00% 8.39%, 55.00% 7.78%, 56.00% 7.21%, 57.00% 6.67%, 58.00% 6.17%, 59.00% 5.73%, 60.00% 5.34%, 61.00% 5.01%, 62.00% 4.75%, 63.00% 4.56%, 64.00% 4.44%, 65.00% 4.40%, 66.00% 4.43%, 67.00% 4.54%, 68.00% 4.72%, 69.00% 4.98%, 70.00% 5.30%, 71.00% 5.68%, 72.00% 6.12%, 73.00% 6.61%, 74.00% 7.14%, 75.00% 7.71%, 76.00% 8.31%, 77.00% 8.93%, 78.00% 9.56%, 79.00% 10.20%, 80.00% 10.82%, 81.00% 11.43%, 82.00% 12.01%, 83.00% 12.56%, 84.00% 13.08%, 85.00% 13.54%, 86.00% 13.95%, 87.00% 14.29%, 88.00% 14.58%, 89.00% 14.79%, 90.00% 14.93%, 91.00% 14.99%, 92.00% 14.99%, 93.00% 14.90%, 94.00% 14.74%, 95.00% 14.51%, 96.00% 14.21%, 97.00% 13.85%, 98.00% 13.43%, 99.00% 12.95%, 100.00% 12.43%);
        h5{
            font-weight: 800;
            font-size: 25px;
            line-height: 30px;
            color: #fff;
            text-align: center;
            padding-bottom: 50px;

        }
        .glider-track{
            gap: 20px;
            background: none ;
        }
        .glider-dots{
            padding-top: 50px;
            background: none ;
        }
        .glider-slide{
            height: fit-content;
            align-self: center;

        }
        .contenedor-card-glider{
            box-shadow: #347c52 15px 14px 20px 0px;
            width: 500px;
            border: solid 1.5px $naranja;
            padding: 15px;
            border-radius: 20px 20px 0 20px ;
            height: fit-content;
            .contenedor-info-card-alumnos{
                border-radius: 0px 20px 0 20px ;
            img{
                width: 100%;
                border-radius: 0px 20px 0 0px ;
            }
            .texto-card-alumnos{
                background-color: #fff;
                border-radius: 0px 0px 0 20px ;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: end;
                .span-card{
                    background-color: $verdeOscuro;
                    padding: 8px;
                    color: #fff;
                    font-weight: 600;
                    width: 180px;
                    border-radius: 20px 0px 0px 20px  ;
                    text-align: center;
                }
                .texto-card{
                    padding: 30px 16px 0 16px;
                    color: $verdeClaro;
                    margin: 0;
                }
    
            }            
            }
    
        }
    
    }
    .button-mobile{
        display: none;
    }
    .body-cambridge{
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: -50px;
    }
}
@media (min-width: 1440px){
    
    .header_idiomas {
        background-size: cover;
    }
    .idiomas-index{
        margin-top: -191px;
    }
    .home .cambridge-alumnos {
        margin-top: -433px;
    }
    .main_index{
        .idiomas{
            padding-top: 230px!important;
        }
        .alumnos{
            .glider-alumnos{
                margin-top: 150px!important;
            }
        }
    }
    .body-cambridge{
        .main_index{
            margin-top: -100px;
            .glider-alumnos{
                margin-top: 400px!important;
            }
        }
    }

}
.openModal{
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.219);
    height: 100%;
    z-index: 10;
    form{
        margin-top: 20px;
        height: max-content;
        z-index: 10;
        .openModalButton{
            width: fit-content;
        }
        .close_button_form{
            display: flex;
            align-self: end;
            button{
                border: $naranja 2px solid;
            }
            button:hover{
                background-color: #fff;
                color: $naranja;
                border: solid 2px $naranja;
            }
        }
        .form-select{
            color: $naranja;
            font-weight: 500;

        }
    }
}
.closeModal{
    display: none;
}
