.infoButton {
  margin-right: auto;
  margin-left: auto;
  background-color: #f3b114;
  width: fit-content;
  padding: 15px;
  border-radius: 0px 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  border: solid 2px #f3b114;
  cursor: pointer;
  font-weight: 800;
}
.infoButton p {
  padding: 0;
  margin: 0;
}
.infoButton:hover {
  background-color: #fff;
  color: #f3b114;
}
a {
  text-decoration: none;
}
